import { Global } from '@emotion/react'
import { ThemeProvider } from '@emotion/react'
import { StytchB2BProvider } from '@stytch/nextjs/b2b'
import { createStytchB2BHeadlessClient } from '@stytch/nextjs/b2b/headless'
import 'highlight.js/styles/github.css'
import App, { AppContext } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import 'rsuite/dist/rsuite.min.css'

import ThemeContext from '@context/theme'
import { darkTheme, getSavedTheme, lightTheme, setSavedTheme } from '@lib/theme'
import '@static/fonts/encodeSans/encodeSans.css'
import '@static/fonts/inter/inter.css'

const stytch = createStytchB2BHeadlessClient(
  process.env.STYTCH_PUBLIC_TOKEN || '',
)

const PortalApp = ({ Component, pageProps, isBotTraffic }: PortalAppProps) => {
  const [theme, setTheme] = useState('light')
  const themeMode = theme === 'light' ? lightTheme : darkTheme

  const setMode = (mode: string) => {
    window.localStorage.setItem('theme', mode)
    setTheme(mode)
    setSavedTheme(mode)
  }

  const toggleTheme = () => {
    theme === 'light' ? setMode('dark') : setMode('light')
  }

  useEffect(() => {
    setTheme(getSavedTheme())
  }, [])

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <ThemeProvider theme={themeMode}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, viewport-fit=cover"
          />
        </Head>
        <Script
          id="isBotTraffic"
          dangerouslySetInnerHTML={{
            __html: `window.isBotTraffic = ${isBotTraffic};`,
          }}
        />
        <StytchB2BProvider stytch={stytch}>
          <Global
            styles={{
              body: {
                backgroundColor: themeMode.colors.white,
                color: themeMode.colors.text.body,
              },
              tspan: {
                color: themeMode.colors.text.axisLabel,
              },
            }}
          />
          <Component {...pageProps} />
        </StytchB2BProvider>
        {!isBotTraffic && (
          <script
            dangerouslySetInnerHTML={{
              __html: `if (!window.isBotTraffic) {analytics.load('${process.env.SEGMENT_WRITEKEY}');}`,
            }}
          />
        )}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

PortalApp.getInitialProps = async (ctx: AppContext) => {
  const initialProps = await App.getInitialProps(ctx)
  const { req } = ctx.ctx

  return {
    ...initialProps,
    isBotTraffic:
      req?.headers?.['user-agent']?.includes('DatadogSynthetics') || false,
  }
}

export default PortalApp
