import { createContext, useContext } from 'react'

// Type.
type ThemeContextType = {
  theme: string
  toggleTheme: () => void
}

// Context.
const ThemeContext = createContext<ThemeContextType | null>(null)

// Provider.
export const EnvironmentProvider = ThemeContext.Provider

// Hook.
export const useTheme = (): ThemeContextType => useContext(ThemeContext)

export default ThemeContext
