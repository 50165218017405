import { Theme } from '@emotion/react'

export const lightTheme: Theme = {
  borderRadius: {
    circle: '50%',
    default: '6px',
    round: '16px',
    rounder: '100px',
  },
  colors: {
    black: '#0A0D15',
    blackMedium: '#151B22',
    error: '#FD5D9E',
    grey: '#394258',
    greyLight: '#F8F7F5',
    info: '#9BC9FF',
    primary: '#3E71F8',
    primaryLight: '#D68DF0',
    primaryLighter: '#789CFA',
    white: '#FFFFFF',
    blue: '#3E71F8',

    backgrounds: {
      green: '#E0FFE8',
      greyLight: '#F8F7F5',
      primary: '#DFE7FF',
      primaryLight: '#EDF1FE',
      red: '#FFEAF3',
      redDark: '#FFDCE8',
    },

    text: {
      axisLabel: '#83868A',
      blockedAddressReasonLabel: '#6B6E73',
      body: '#394258',
      breadcrumb: '#697286',
      button: '#393D44',
      buttonRed: '#FD5D9E',
      disabledButton: '#9C9EA1',
      green: '#4BC26B',
      headline: '#0A0D15',
      navigation: '#16171A',
      orange: '#FFA500',
      red: '#FD5D9E',
      redDark: '#DB4E87',
      settingsLabel: '#393D44',
      yellow: '#d0ae00',
    },

    border: {
      default: '#DFDEDC',
      light: '#F5F5F5',
    },
  },
}

export const darkTheme: Theme = {
  borderRadius: {
    circle: '50%',
    default: '6px',
    round: '16px',
    rounder: '100px',
  },
  colors: {
    black: '#0A0D15',
    blackMedium: '#151B22',
    blue: '#3E71F8',
    error: '#FD5D9E',
    grey: '#FFFFFF',
    greyLight: '#F8F7F5',
    info: '#9BC9FF',
    primary: '#FFFFFF',
    primaryLight: '#D68DF0',
    primaryLighter: '#789CFA',
    white: '#FFFFFF',

    backgrounds: {
      green: '#E0FFE8',
      greyLight: '#252C40',
      primary: '#141A2D',
      primaryLight: '#252C40',
      red: '#B11C1F',
      redDark: '#831416',
    },

    text: {
      axisLabel: '#83868A',
      blockedAddressReasonLabel: '#6B6E73',
      body: '#b4bac3',
      breadcrumb: '#697286',
      button: '#FFFFFF',
      buttonRed: '#FFFFFF',
      disabledButton: '#9C9EA1',
      green: '#4BC26B',
      headline: '#FFFFFF',
      navigation: '#b4bac3',
      orange: '#FFA500',
      red: '#ff5555',
      redDark: '#d83d3d',
      settingsLabel: '#393D44',
      yellow: '#d0ae00',
    },

    border: {
      default: '#141A2D',
      light: '#252C40',
    },
  },
  isDark: true,
}

export const getSavedTheme = () => {
  if (typeof window === 'undefined') {
    return 'light'
  }
  return window.localStorage.getItem('theme') || 'light'
}

export const setSavedTheme = (mode: string) => {
  window.localStorage.setItem('theme', mode)
}

export default darkTheme
